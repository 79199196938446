.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.tal {
  text-align: left;
}

.ttuc {
  text-transform: uppercase;
}

.ttlc {
  text-transform: lowercase;
}

.db {
  display: block;
}

.dib {
  display: inline-block;
}

.mc {
  display: block;
  width: max-content;
  margin: 0 auto;
}

.cp {
  cursor: pointer;
}

.tdu {
  border-bottom: 2px solid $sysColorPink;
}

.pr {
  position: relative;
}

.pa {
  position: absolute;
}

.pf {
  position: fixed;
}