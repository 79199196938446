@import "vars";

@font-face {
  font-family: "futura";
  src: url("./fonts/FuturaPTMedium.otf") format("opentype");
}

@font-face {
  font-family: "futura-light";
  src: url("./fonts/FuturaPTLight.otf") format("opentype");
}

@font-face {
  font-family: "futura-bold";
  src: url("./fonts/FuturaPTBold.otf") format("opentype");

}

.bold {
  font-family: 'futura-bold';
}

.normal {
  font-family: 'futura';
}

* {
  outline: none !important;
}

body {
  padding: 0;
  margin: 0;
  font-family: "futura";

  &.app-container {
    overflow: hidden;
  }
}

.bp4-dialog {
  margin: 36px 20px !important;
  padding-bottom: 0 !important;
}

button {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
}

.bp4-dark {
  color: $pt-dark-text-color;
}

.bp4-light {
  background-color: $tiny-gray;
  color: $dark-gray1;
}

.border-b {
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
}

.fixed {
  position: fixed;

  &.top {
    top: 0;
    left: 0;
  }
}


.bp4-light {
  .bg {
    background: #fff;
  }

  .shadow {
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.07);

    &.hover:active,
    &.hover:focus,
    &.hover:hover {
      box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
    }
  }


  a:hover,
  a:active,
  a:focus,
  a {
    color: $pt-text-color;
    text-decoration: none;
  }

  button:hover,
  button:active,
  button:focus,
  button {
    color: inherit !important;
    text-decoration: none;
    text-align: inherit !important;

    span {
      text-align: inherit !important;
    }
  }
}

.bp4-dark {
  .bg {
    background: $gray1;
  }

  .shadow {
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.15);

    &.hover:active,
    &.hover:focus,
    &.hover:hover {
      box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.4);
    }
  }

  a:hover,
  a:active,
  a:focus,
  a {
    color: $pt-dark-text-color;
    text-decoration: none;
  }

  button:hover,
  button:active,
  button:focus,
  button {
    color: inherit !important;
    text-decoration: none;
    text-align: inherit !important;

    span {
      text-align: inherit !important;
    }
  }
}

button,
a {
  outline: none !important;
}

button {
  border: none;
}

a {
  padding: 0;
}

@import "~@blueprintjs/core/lib/css/blueprint.css";
//@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./util";
@import "./site";
