@import "~@blueprintjs/core/lib/scss/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";


$pt-font-size: 16px;

//$grid-columns: 100;
$gutter: 20px;
$content-well-max-width: 'none';
$tiny-gray: #ededed;


$fsXl: 105px;
$fsLg: 65px;
$fsMd: 28px;
$fsSm: 25px;
$fsXs: 18px;

$fsScale: 25;
$fsScaleLg: 29;
$fsScaleMd: 5;
$fsScaleSm: 4;
$fsScaleXs: 10;

.fsXl {
  font-size: $fsXl - $fsScale;
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    font-size: $fsXl;
  }
}

.fsLg {
  font-size: $fsLg - $fsScaleLg;
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    font-size: $fsLg;
  }
  @media (min-width: 885px) and (max-width: 1210px) {
    font-size: $fsLg - 25;
  }
}

.fsMd {
  font-size: $fsMd - $fsScaleMd;
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    font-size: $fsMd;
  }
}

.fsSm {
  font-size: $fsSm - $fsScaleSm;
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    font-size: $fsSm;
  }
}

.fsXs {
  font-size: $fsXs - $fsScaleXs;
  @media (min-width: map-get($grid-breakpoints, "xs")) {
    font-size: $fsXs;
  }
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    font-size: $fsXs + 1;
  }
}


$sysColorPink: #E31DA3;
$sysColorBlue: #6D39F6;