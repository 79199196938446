
.light {
  font-family: "futura-light";
}

.color-pink {
  color: $sysColorPink !important;
}

.color-blue {
  color: $sysColorBlue !important;
}

.logo {
  width: 6em;
  margin-top: 4px;
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    margin-top: inherit;
    width: 8em;
  }
}

#root {
  overflow: hidden;
}

body {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(90deg, #08001D 0%, #120213 100%);
  overflow-x: hidden;
  width: 100%;

  margin: 0 auto;
}

.plan-items {
  @media (min-width: map-get($grid-breakpoints, "xs")) {
    display: block !important;
    //margin-bottom: -23px;
    .item {
      width: 100%;
      margin-top: 30px;
    }
  }
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    margin-bottom: 0;
    display: inherit !important;
    .item {
      width: auto;
      margin-top: inherit;
    }
  }

}

.container {
  @media (min-width: map-get($grid-breakpoints, "xs")) {
    padding: 0 40px;
  }

  @media (min-width: map-get($grid-breakpoints, "sm")) {
    padding: 0 40px;
  }
  @media (min-width: map-get($grid-breakpoints, "md")) {
    overflow: inherit;
    padding: 0;
  }
}

.app-container {
  background-repeat: no-repeat;
  background-position: center -80px;
  position: relative;
  padding-top: 50px;
  z-index: 2;
  min-height: 100vh;
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    //background-image: url("./img/bg_n.png");
  }
}


.bg_logo_gray {
  background-image: url("./img/bg_n.png");
  position: absolute;
  width: 613px;
  height: 610px;
  background-position: -249px -19358px;
  left: -430px;
  background-size: 2000px;
  border-radius: 290px;
  top: -143px;
}

.bg_logo,
.bubble_lg_left,
.bubble_lg_right {
  background-image: url("./img/bg_n.png");
  position: absolute;
  z-index: -1;
}

.bg_logo1_xs,
.bubble_xs_left,
.bubble_xs_right {
  background-image: url("./img/bg_m.png");
  position: absolute;
  z-index: -1;
}


.bg_logo {
  background-image: url("./img/bg_m.png");
  top: -50px;
  left: -920px;
  width: 100000px;
  height: 700px;
  background-position: 690px -40px;
  background-repeat: no-repeat;

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    width: 10000px;
    height: 910px;
    background-image: url("./img/bg_n.png");
    background-position: 240px -40px;
    left: -1000px;
    background-size: 3000px;
  }

}

.bubble_lg_left {
  opacity: 0.1;

  &.blue {
    width: 2390px;
    height: 625px;
    background-position: 119px -4579px;
    background-size: 1804px;
    background-repeat: no-repeat;
    top: -100px;
    transform: rotate(180deg);
    left: -1550px;
  }

  &.red {
    width: 2390px;
    height: 625px;
    background-position: 119px -4579px;
    background-size: 1804px;
    background-repeat: no-repeat;
    top: -150px;
    transform: rotate(360deg);
    left: -1350px;
  }
}

.bubble_lg_right {
  width: 2390px;
  height: 625px;
  background-position: -419px -4579px;
  background-size: 1804px;
  background-repeat: no-repeat;
  top: -900px;
  transform: rotate(280deg);
  left: -350px;

  &.red2 {
    top: -150px;
    left: 450px;
    transform: rotate(360deg);
  }

  &.red3 {
    top: 550px;
    left: 250px;
    transform: rotate(100deg);
  }
}

.bubble_xs_left {

}

.bubble_xs_right {

}


.top-side {
  position: relative;
}

.main-menu {
  .main_menu_link {
    font-size: 18px;
    margin-left: 30px;
    @extend .light;
  }
}

.switch-language-bt {
  margin-right: 25px;
  @extend .light;
  position: relative;

  .caret {
    display: inline-block;
    position: absolute;
    top: -3px;
    right: -13px;
    transform: rotate(-180deg);

    &.open {
      transform: rotate(0);
      top: 3px;
    }
  }

  .lang-list {


    position: absolute;
    display: none;
    left: 0;
    top: 100%;

    &.open {
      display: inline-block;
    }

    .lang-item {
      display: inline-block;
      padding-top: 10px;
    }
  }
}

.buy-token {
  background: linear-gradient(92.14deg, #E31DA3 4.36%, #6E39F6 96.26%);
  border-radius: 75px;
  padding: 10px 5px;
  margin-top: 3px;
  display: inline-block;
  position: relative;
  @extend .light;


  min-width: 106px;
  @media (min-width: map-get($grid-breakpoints, "sm")) {
    min-width: 140px;
  }


  text-align: center;

  &:hover {
    box-shadow: 0 0 15px 0 rgba(227, 29, 163, 0.56), 0 0 15px 0px rgba(110, 57, 246, 0.61);
  }
}

.download-link {
  opacity: 0.9;
  position: relative;

  &:hover {
    opacity: 1;
  }

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    background: url("./img/file.svg");
    background-size: contain;
    background-repeat: no-repeat;
    padding: 17px;
    left: -35px;
  }
}

//header
.header {
  @extend .fsLg;
  @extend .bold;

  margin-top: 30px;

  &.top-header {
    .blue {
      font-size: 59px;
      word-spacing: 100px;
      line-height: 55px;
      @media (min-width: map-get($grid-breakpoints, "xs")) {
        line-height: 55px;
      }
      @media (min-width: map-get($grid-breakpoints, "md")) {
        line-height: inherit;
      }
    }

    @media (min-width: map-get($grid-breakpoints, "sm")) {
      .blue {
        font-size: inherit;
        word-spacing: inherit;
      }
    }
    @media (min-width: map-get($grid-breakpoints, "lg")) {
      margin-top: 100px;
    }
  }

  span.blue {
    @extend .fsXl;
    color: #6D39F6;
    display: block;
  }
}

.advantages {

  padding-bottom: 10px;
  position: relative;

  $pad: 40px;

  .items {
    margin-top: 0;
    @media (min-width: map-get($grid-breakpoints, "md")) {
      margin-top: 100px;
    }

    .left-container {
      border-right: none;
      border-left: 2px dashed #E31DA3;
      padding: 0 0 0 22px;

      @media (min-width: map-get($grid-breakpoints, "md")) {
        border-left: none;
        border-right: 2px dashed #E31DA3;
        text-align: right;
        padding: inherit;
      }

      .item {
        & > div:after {
          left: -38.5px;
          @media (min-width: map-get($grid-breakpoints, "md")) {
            right: -28.5px;
            left: unset;
          }
        }
      }
    }

    .right-container {

      padding: 0 0 0 22px;
      border-left: 2px dashed #E31DA3;
      @media (min-width: map-get($grid-breakpoints, "md")) {
        border-left: none;
        text-align: left;
        padding: inherit;
      }

      .item {
        & > div:after {
          @media (min-width: map-get($grid-breakpoints, "xs")) {
            left: -39.5px;
          }
          @media (min-width: map-get($grid-breakpoints, "md")) {
            left: -29.5px;
          }
        }
      }
    }
  }

  .item {
    min-height: 135px;
    position: relative;
    padding-left: 10px;
    @media (min-width: map-get($grid-breakpoints, "md")) {
      padding-left: 0;

    }

    & > div:after {
      content: '';
      border: 6px solid #E31DA3;
      position: absolute;
      padding: 10px;
      border-radius: 100px;
      top: 25%;
      background: rgb(13, 1, 24);
      box-shadow: 0 0 15px rgba(175, 8, 121, 0.87);
    }

    margin-top: 50px;


    @media (min-width: map-get($grid-breakpoints, "md")) {
      &.item-1 {
        margin-top: 40px;
        height: 270px;
      }

      &.item-2 {
        margin-top: 200px;
      }

      &.item-3 {
        margin-top: 40px;
      }

      &.item-4 {
        margin-top: 130px;
      }

      &.item-5 {
        margin-top: 150px;
      }

      &.item-6 {
        margin-top: 150px;
      }
    }

    @media (min-width: map-get($grid-breakpoints, "md")) {
      &.left {
        padding-right: $pad;

      }

      &.right {
        padding-left: $pad;
      }
    }
  }
}

.market {
  position: relative;
  margin-top: 80px;

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    padding-bottom: 500px;
  }

  .box {
    padding: 40px 50px;
    border: 2px dashed #6D39F6;
    background: linear-gradient(142.61deg, rgba(109, 57, 246, 0.2) 0%, rgba(227, 29, 163, 0.2) 100%);
    border-radius: 50px;
    line-height: 30px;
    width: 100%;
    margin-bottom: 30px;
    @media (min-width: map-get($grid-breakpoints, "lg")) {
      width: 450px;
      position: absolute;
      $t: 0;
      &.box-1 {
        right: 100px;
        top: $t;
        width: 450px;
        min-height: 369px;
      }
      &.box-2 {
        width: 600px;
        right: 400px;
        top: $t + 345px;
      }
      &.box-3 {
        right: 30px;
        top: $t + 440px;
        width: 400px;
      }
    }
  }
}


.info {

  @media (min-width: map-get($grid-breakpoints, "lg")) {
    margin-top: 100px;
  }
  @media (min-width: map-get($grid-breakpoints, "xl")) {
    margin-top: 0;

  }

  .neon {
    color: black;
    font-family: 'futura-bold';
    font-size: 18px;
    @media (min-width: map-get($grid-breakpoints, "md")) {
      font-size: $fsLg - 20;
    }
    @media (min-width: map-get($grid-breakpoints, "lg")) {
      font-size: $fsLg - 20;
    }
    text-shadow: 0px 0px 4px #6D39F6;
    -webkit-text-fill-color: black; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #6D39F6;
  }

  .item-3,
  .item-2,
  .item-1 {
    margin-bottom: 60px;
    @media (min-width: map-get($grid-breakpoints, "lg")) {
      margin-bottom: 0;
    }
  }
}

.plans {
  .title-info {
    @media (min-width: map-get($grid-breakpoints, "lg")) {
      text-align: center;
    }
  }

  .items-container {
    text-align: center;
    position: relative;

    .items-title {
      margin-top: 40px;
      border: 2px solid #6D39F6;
      box-sizing: border-box;
      border-radius: 24px;
      display: inline-block;
      padding: 10px 40px;
      background: rgb(13, 1, 24);
      z-index: 1;

      @extend .fsMd;
      @extend .bold;

      @media (min-width: map-get($grid-breakpoints, "lg")) {
        &:before,
        &:after {
          z-index: -1;
          content: '';
          display: block;
          position: absolute;
        }
        &:before {
          border-left: 2px dashed #6D39F6;
          height: 150px;
          left: 0;
          top: 90px;
        }
        &:after {
          border-top: 2px dashed #6D39F6;
          width: 530px;
          left: 0;
          top: 90px;
        }

      }
    }


    .items {

      overflow: hidden;
      @extend .fsXs;
      padding-bottom: 10px;

      .item-group {
        position: relative;
        margin-bottom: 0;
        @media (min-width: map-get($grid-breakpoints, "lg")) {
          margin-bottom: 3.33%;
        }

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          border-top: 2px dashed #6D39F6;
          width: 100%;
        }

        &.item-group1 {
          margin-top: 50px;
        }

        &.item-group3,
        &.item-group1 {
          &:before {
            top: 54%;
            right: 12px;
            width: 2px;
            height: 110px;
            @media (min-width: map-get($grid-breakpoints, "xs")) {
              display: none;
            }
            @media (min-width: map-get($grid-breakpoints, "lg")) {
              display: inherit;
              border-right: 2px dashed #6D39F6;
            }
          }
        }

        &.item-group4,
        &.item-group2 {
          &:before {
            top: 54%;
            left: 12px;
            width: 2px;
            height: 110px;
            @media (min-width: map-get($grid-breakpoints, "lg")) {
              border-left: 2px dashed #6D39F6;
            }
          }
        }

        &.item-group4 {
          &:before {
            border-left: none !important;
            display: none;
          }

          &:after {
            width: 80%;
            left: 20%;
          }
        }
      }

      .show-all-items {
        @extend .light;
        display: block;
        position: relative;
        top: 43px;
        padding-top: 10px;
        text-decoration: underline !important;

        &:after {
          content: '';
          position: absolute;
          @media (min-width: map-get($grid-breakpoints, "xs")) {
            width: 31%;
          }
          @media (min-width: map-get($grid-breakpoints, "sm")) {
            width: 38%;
          }
          @media (min-width: map-get($grid-breakpoints, "md")) {
            width: 41%;
          }
          top: 75%;
          right: 5px;
          border-bottom: 2px dashed $sysColorBlue;
        }

        @media (min-width: map-get($grid-breakpoints, "lg")) {
          display: none;
        }
      }

      .item {

        &.mobile-item-hide {
          display: none;
        }

        &.mobile-item-hide.show {
          display: block;
        }

        @media (min-width: map-get($grid-breakpoints, "lg")) {
          &.mobile-item-hide {
            display: block !important;
          }
        }

        text-align: left;
        background: #281F37;
        border-radius: 16px;
        @extend .light;
        display: inline-block;
        padding: 15px 25px 15px 40px;
        position: relative;
        z-index: 1;
        line-height: 24px;


        .left-border-container {
          overflow: hidden;
          position: absolute;
          border-radius: 16px;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;

          .left-border {
            display: block;
            position: absolute;
            background-color: $sysColorBlue;
            height: 100px;
            width: 10px;
            top: 0;
            left: 0;
            border-radius: 16px 0px 0px 16px;
          }
        }
      }
    }


  }

  @media (min-width: map-get($grid-breakpoints, "xs")) {
    .items {
      overflow: visible !important;
    }
    .items-title .border-xs {
      z-index: -1;
      display: block !important;
      width: 50%;
      border-left: 2px dashed $sysColorBlue;
      border-top: 2px dashed $sysColorBlue;
      border-bottom: 2px dashed $sysColorBlue;
      height: 160px;
      position: absolute;
      left: -28px;
      top: 90px;
    }


    .border-xs-13,
    .border-xs-11,
    .border-xs-9,
    .border-xs-7,
    .border-xs-5,
    .border-xs-3,
    .border-xs-1 {
      z-index: -1;
      display: block !important;
      width: 27px;
      border-right: 2px dashed #6D39F6;
      border-top: 2px dashed #6D39F6;
      border-bottom: 2px dashed #6D39F6;
      height: 100px;
      position: absolute;
      right: -28px;
      top: 50%;
    }


    .border-xs-14,
    .border-xs-12,
    .border-xs-10,
    .border-xs-8,
    .border-xs-6,
    .border-xs-4,
    .border-xs-2 {
      z-index: -1;
      display: block !important;
      width: 27px;
      border-left: 2px dashed #6D39F6;
      border-top: 2px dashed #6D39F6;
      border-bottom: 2px dashed #6D39F6;
      height: 100px;
      position: absolute;
      left: -28px;
      top: 50%;
    }

    .item-group {
      &:after {
        border: none !important;
      }
    }
  }
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    .border-xs-n {
      display: none !important;
    }
    .items-title .border-xs {
      display: none !important;
    }
    .items {
      overflow: hidden !important;
    }
    .item-group {
      &:after {
        border-top: 2px dashed $sysColorBlue !important;
      }
    }
  }

}


.white-paper {
  .default {
    .color-blue {
      position: relative;
      margin-left: 40px;

      &:after {
        display: inline-block;
        content: '';
        padding: 6px;
        border: 4px solid $sysColorBlue;
        position: absolute;
        border-radius: 100px;
        left: -40px;
        top: 7px;
        box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
      }
    }

    .desc {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: 40px;
      @media (min-width: map-get($grid-breakpoints, "lg")) {
        max-width: 800px;
      }
    }
  }


  .wr-token {
    position: relative;

    .under-text {
      padding: 50px 0;
    }

    .under-text {
      background: url("./img/wr-token.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 100%;
      padding: 12px;
      color: transparent;
      margin-bottom: 10px;
      @media (min-width: map-get($grid-breakpoints, "xxl")) {
        padding: 12px;
        position: relative;
        .left,
        .right {
          position: absolute;
          display: inline-block;
          width: 10%;
          top: 50%;

          &:after {
            content: '';
            position: absolute;
            display: block;
            padding: 10px;
            border: 4px solid $sysColorPink;
            border-radius: 100px;
            box-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
            top: -13px;
          }
        }
        .right {
          right: 350px;
          border-bottom: 3px dashed $sysColorPink;

          &:after {
            right: -35px;
          }
        }
        .left {
          left: 350px;
          border-bottom: 3px dashed $sysColorBlue;

          &:after {
            left: -35px;
            border-color: $sysColorBlue;
          }

        }
      }
    }

    .title {
      margin: 20px auto;
      @media (min-width: map-get($grid-breakpoints, "lg")) {
        text-align: center;
        width: 170px;
        margin: 30px auto;
      }
    }

    .desc {
      @media (min-width: map-get($grid-breakpoints, "lg")) {
        padding: 20px;
      }
    }

    @media (min-width: map-get($grid-breakpoints, "xs")) {
      .title {
        position: relative;
        padding-left: 48px;

        &:after {
          content: '';
          display: inline-block;
          padding: 6px;
          border-radius: 100px;
          position: absolute;
          left: -8px;
          top: 6px;
          border: 4px solid $sysColorBlue;
        }

        &.title-1:after {
          border-color: $sysColorPink;
        }
      }
      .desc {
        padding-left: 60px;
      }
    }

    @media (min-width: map-get($grid-breakpoints, "lg")) {
      .title {
        padding-left: inherit;

        &:after {
          display: none;
        }
      }
      .desc {
        padding-left: inherit;
      }
    }
  }
}

.how-to-use {
  .video {
    background-image: url("./img/video_bg_m.png");
    margin-top: 15px;
    height: 300px;
    @media (min-width: map-get($grid-breakpoints, "lg")) {
      background-image: url("./img/video_bg.png");
      height: 400px;
    }
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
  }

  .text-1 {
    @media (min-width: map-get($grid-breakpoints, "xs")) {
      margin-bottom: 10px;
    }
    @media (min-width: map-get($grid-breakpoints, "md")) {
      margin-bottom: 0;
      left: -10px;
    }
    @media (min-width: map-get($grid-breakpoints, "lg")) {
      position: relative;
      left: -40px;
      top: -5px;
    }
    @media (min-width: map-get($grid-breakpoints, "xl")) {
      position: relative;
      left: -10px;
      top: 10px;
    }
    @media (min-width: map-get($grid-breakpoints, "xxl")) {
      position: relative;
      left: -30px;
      top: 10px;
    }
  }

  .text-2 {
    @media (min-width: map-get($grid-breakpoints, "xs")) {
      margin-bottom: 10px;
    }
    @media (min-width: map-get($grid-breakpoints, "md")) {
      margin-bottom: 0;
      left: -10px;
    }
    @media (min-width: map-get($grid-breakpoints, "lg")) {
      position: relative;
      left: -120px;
      top: -5px;
    }
    @media (min-width: map-get($grid-breakpoints, "xl")) {
      position: relative;
      left: -20px;
      top: 15px;
    }
    @media (min-width: map-get($grid-breakpoints, "xxl")) {
      position: relative;
      left: -110px;
      top: 15px;
    }
  }

  .separator {
    @media (min-width: map-get($grid-breakpoints, "xs")) {
      padding-bottom: 100px;
    }
    @media (min-width: map-get($grid-breakpoints, "lg")) {
      padding-bottom: 0;
    }
  }

  .promo-last-block {
    @media (min-width: map-get($grid-breakpoints, "xs")) {
      position: absolute;
      right: -30%;
    }
    @media (min-width: map-get($grid-breakpoints, "sm")) {
      position: absolute;
      right: -1%;
    }
    @media (min-width: map-get($grid-breakpoints, "md")) {
      position: absolute;
      right: -10%;
    }
    @media (min-width: map-get($grid-breakpoints, "lg")) {
      position: inherit;
      right: inherit;
    }
  }
}

.social-link {
  padding: 5px 17px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 30% !important;
  display: inline-block;

  border: 3px solid $sysColorPink;
  border-radius: 100px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.4);

  margin: 50px 10px;
  @media (min-width: map-get($grid-breakpoints, "sm")) {
    margin: 50px 20px;
  }

  &.f {
    background: url("./img/social/f.svg");
  }

  &.wk {
    background: url("./img/social/wk.svg");
    background-size: 60% !important;
  }

  &.in {
    background: url("./img/social/in.svg");
    background-size: 50% !important;
  }

  &.od {
    background: url("./img/social/od.svg");
    background-size: 40% !important;
  }
}